import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 617.000000 624.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,624.000000) scale(0.100000,-0.100000)">
	<path d="M0 3120 l0 -3120 3085 0 3085 0 0 3120 0 3120 -3085 0 -3085 0 0
-3120z m3960 2605 c253 -61 487 -221 675 -460 152 -194 267 -446 324 -709 26
-117 26 -120 4 -111 -10 4 -52 13 -93 19 l-74 11 -17 66 c-9 36 -29 95 -43
129 -14 35 -23 67 -20 72 3 4 0 8 -6 8 -7 0 -9 7 -6 16 4 10 2 13 -6 8 -8 -5
-9 -1 -4 12 4 10 4 15 0 12 -3 -4 -23 26 -43 65 -38 74 -135 232 -155 253 -6
6 -31 36 -56 65 -25 29 -64 71 -87 93 -23 22 -39 44 -36 49 3 6 1 7 -4 4 -6
-4 -21 4 -34 16 -55 54 -173 127 -306 190 -29 13 -50 27 -47 30 3 3 1 8 -5 12
-6 3 -11 2 -11 -4 0 -6 -8 -8 -17 -5 -10 3 -25 8 -33 10 -13 5 -13 6 0 14 13
8 12 10 -2 10 -10 0 -18 -4 -18 -9 0 -5 -21 -3 -47 5 -42 13 -85 20 -145 23
-10 1 -18 8 -18 16 0 8 -4 15 -8 15 -10 0 -15 87 -6 102 12 18 227 1 344 -27z
m-169 -201 c423 -107 768 -456 940 -952 17 -51 30 -95 27 -97 -2 -3 -24 1 -48
8 -39 11 -46 18 -82 87 -127 239 -308 436 -518 563 -132 80 -273 129 -420 147
-123 15 -137 19 -143 41 -3 10 -2 21 2 23 7 5 12 45 14 130 2 41 -2 52 -23 69
l-25 20 86 -7 c47 -3 132 -18 190 -32z m-456 -4 c19 -8 19 -9 -2 -9 -25 -1
-32 -21 -8 -21 20 0 19 -18 -2 -22 -15 -3 -15 -4 1 -21 10 -10 15 -21 12 -25
-4 -3 -11 0 -16 8 -14 23 -24 2 -12 -25 9 -20 8 -26 -6 -34 -15 -9 -15 -10 3
-11 11 -1 14 -4 8 -7 -7 -2 -13 -12 -13 -21 0 -15 2 -15 10 -2 13 20 13 8 0
-25 -7 -18 -15 -25 -25 -21 -8 3 -15 1 -15 -4 0 -8 -56 -24 -85 -25 -5 0 -14
-5 -20 -11 -5 -6 -46 -26 -90 -44 -197 -79 -365 -189 -535 -349 -59 -56 -68
-61 -100 -57 -19 3 -61 8 -92 11 -32 4 -58 10 -58 15 0 20 131 181 225 276
109 111 204 184 242 184 13 0 22 4 18 9 -3 5 -1 12 5 16 5 3 10 2 10 -3 0 -13
104 -34 175 -36 33 -1 66 -5 73 -9 7 -5 12 -3 12 3 0 8 14 11 39 8 37 -3 38
-2 23 13 -9 9 -20 15 -23 13 -11 -6 -17 61 -7 67 6 4 -3 8 -19 11 -25 3 -32
-1 -42 -23 -9 -20 -17 -26 -31 -22 -11 3 -20 9 -20 14 0 5 -12 9 -26 9 -14 0
-23 4 -19 10 3 6 -1 10 -10 10 -9 0 -13 -4 -10 -10 3 -5 1 -10 -4 -10 -6 0
-11 4 -11 10 0 13 58 42 70 35 6 -3 10 0 10 8 0 8 12 18 28 22 15 3 34 12 42
19 18 16 233 84 265 84 6 1 19 -3 30 -8z m-455 -189 c0 -17 -6 -31 -16 -35
-12 -4 -14 -1 -9 14 8 24 -3 25 -33 4 -12 -8 -22 -11 -22 -6 0 6 56 44 78 51
1 1 2 -12 2 -28z m590 15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m-420 -25 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5
16 10 16 6 0 10 -4 10 -9z m747 -218 c65 -19 155 -91 206 -163 103 -148 118
-388 32 -535 -31 -53 -99 -118 -155 -147 -38 -19 -60 -23 -135 -23 -79 0 -96
4 -140 27 -92 50 -107 72 -95 136 7 40 6 44 -24 66 -17 13 -44 28 -61 33 -44
13 -75 82 -82 180 -9 125 34 256 111 337 31 34 38 37 70 30 32 -5 36 -4 36 15
0 38 81 73 150 64 19 -2 58 -11 87 -20z m467 -778 c-35 -121 -78 -265 -94
-320 -16 -55 -56 -192 -89 -305 -33 -113 -71 -242 -84 -287 -27 -94 -42 -119
-33 -53 3 26 2 37 -2 26 -7 -17 -11 -16 -35 14 -18 21 -26 39 -22 51 7 22 -2
59 -15 59 -5 0 -5 -13 0 -30 12 -43 -2 -37 -30 11 -33 60 -100 119 -100 89 0
-14 -402 -681 -407 -676 -3 3 11 33 30 68 30 54 51 89 115 193 7 11 55 92 107
180 78 130 92 161 80 168 -8 4 -21 5 -28 2 -6 -4 -3 1 7 9 14 11 23 13 32 5 9
-8 16 -5 24 12 7 12 8 19 2 15 -6 -3 -13 0 -16 6 -3 7 -4 2 -1 -12 5 -25 5
-25 -6 -2 -14 26 -25 28 -33 6 -4 -9 -9 -14 -12 -11 -3 3 0 14 7 26 15 23 6
39 -13 23 -9 -7 -17 -6 -26 4 -10 10 -10 14 -1 14 7 0 10 3 6 6 -3 4 -14 4
-23 0 -11 -4 -13 -10 -7 -19 6 -8 5 -21 -3 -37 -13 -24 -14 -23 -15 25 -1 50
-1 50 -9 12 -7 -39 -16 -54 -26 -45 -3 3 23 116 57 250 34 133 67 269 73 300
l11 57 105 -159 c57 -88 107 -160 110 -160 4 0 72 103 110 169 8 14 72 118
141 231 70 114 137 230 149 258 13 29 24 51 26 50 1 -2 -26 -102 -62 -223z
m-1794 163 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z m168
-22 c47 -24 92 -61 92 -74 0 -4 -7 -16 -15 -26 -12 -16 -15 -17 -15 -4 0 9 6
18 13 21 16 6 -13 30 -85 67 -39 20 -58 26 -59 18 0 -10 -2 -10 -6 0 -2 6 -22
15 -44 18 -21 4 -39 10 -39 14 0 13 112 -11 158 -34z m194 -16 c23 0 38 -3 34
-7 -4 -4 -23 -6 -43 -4 -30 2 -35 0 -30 -14 4 -9 3 -14 -3 -10 -6 3 -10 12
-10 20 0 7 -3 15 -7 18 -5 2 -2 2 5 0 8 -1 32 -3 54 -3z m-498 -35 c-3 -7 -12
-16 -20 -19 -9 -3 -12 -1 -9 4 3 6 -1 10 -10 10 -9 0 -14 4 -10 10 3 5 12 7
20 3 11 -4 13 -1 8 13 -6 17 -5 17 10 6 8 -8 13 -20 11 -27z m315 1 c12 0 21
-4 21 -8 0 -5 0 -12 0 -18 0 -5 -8 -10 -17 -10 -15 0 -14 2 3 15 14 11 15 15
4 14 -35 -3 -50 2 -50 19 0 13 2 14 9 3 5 -7 18 -14 30 -15z m317 16 c14 -9 6
-322 -8 -322 -7 0 -8 56 -3 159 7 151 6 159 -11 158 -11 -1 -22 -9 -26 -17 -5
-13 -7 -12 -7 2 -1 9 -9 19 -18 21 -10 3 0 5 22 6 22 0 45 -3 51 -7z m-601
-32 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-30 -20 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m120 -10 c-3 -5
-12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m32 -22 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m148 -6 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12
-12z m90 8 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4
10 -10z m112 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-570
-20 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 0 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m220 -10 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m287 -3 c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19
12 19 11 13 -6z m-320 -10 c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11
13 -6z m-154 -5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m367 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-186 -26 c-18 -34 -13 -77 12 -99 12 -11 74 -55 137 -98 206 -140 226 -159
278 -265 35 -71 37 -80 36 -170 -1 -110 -19 -163 -80 -230 -38 -41 -114 -89
-114 -71 0 5 9 13 20 16 11 4 17 11 14 17 -4 7 -2 8 4 4 18 -11 82 62 114 129
50 107 33 259 -42 360 -16 22 -34 39 -40 37 -6 -1 -9 2 -6 7 8 12 -49 53 -59
43 -5 -4 -5 -2 -2 4 8 13 -49 57 -63 49 -6 -3 -10 0 -10 7 0 7 -41 39 -90 71
-134 88 -165 137 -124 195 19 28 30 22 15 -6z m-144 -4 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-48 -30 c-10 -19 -29 -25 -29 -8 0 6 5 10 11
10 5 0 8 4 5 8 -3 5 1 9 9 9 10 0 11 -5 4 -19z m591 12 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m1862 -10 c22 -6 55 -29
80 -55 24 -25 46 -45 51 -45 4 0 7 14 7 30 0 34 8 35 95 23 l48 -6 -7 -186
c-4 -102 -9 -187 -10 -188 -1 -1 -32 1 -70 5 l-68 7 -20 50 c-10 28 -35 65
-54 84 -31 29 -41 32 -72 28 -86 -14 -112 -83 -119 -317 -8 -244 20 -320 118
-320 48 0 70 29 77 101 l5 49 -33 0 -33 0 7 46 c3 26 6 72 6 103 l0 57 128
-12 c70 -7 131 -13 136 -13 13 -1 -1 -205 -19 -276 -33 -130 -115 -245 -198
-280 -58 -24 -213 -1 -265 40 -8 5 -27 22 -44 37 -16 15 -33 28 -37 30 -13 6
-81 123 -91 158 -64 222 -43 516 47 674 71 123 153 186 243 186 30 0 72 -5 92
-10z m-1915 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-680
-30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m105 -10 c-8 -8 -9
-4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m509 15 c13 -16 12 -17 -3 -4
-10 7 -18 15 -18 17 0 8 8 3 21 -13z m75 -31 c-4 -3 -11 0 -16 8 -15 24 -11
33 6 15 9 -9 13 -19 10 -23z m41 -4 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-397 -11 c0 -7 -4 -6 -10 3 -8 12 -10 12 -10 1 0 -8 -3 -12 -6 -8
-9 9 6 28 17 22 5 -4 9 -12 9 -18z m41 -24 c13 -16 12 -17 -2 -5 -14 10 -20
10 -33 -3 -9 -9 -16 -13 -16 -8 0 11 21 33 31 33 4 0 13 -8 20 -17z m-321 -48
c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m180
25 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m-46 -52 c-5 -7
-2 -9 8 -5 11 4 15 0 14 -11 -2 -9 -9 -18 -17 -20 -12 -3 -12 -2 0 6 11 8 9
11 -8 17 -20 6 -20 25 0 25 6 0 7 -5 3 -12z m280 0 c-3 -4 -9 -8 -15 -8 -5 0
-9 4 -9 8 0 5 7 9 15 9 8 0 12 -4 9 -9z m-334 -8 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m322 -62 c-7 -7 -12 -8 -12 -2 0
6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m-345 -10 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m310 -15 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18
2 -7 2 -21 0 -30z m153 33 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14
25 6z m-253 -68 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-133
-4 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z m153 -26 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m327 -10 c-5 -7 -2 -9 9 -5 11
4 14 2 10 -9 -4 -11 -9 -12 -19 -3 -12 10 -11 29 2 29 3 0 2 -5 -2 -12z m-110
-13 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z
m-241 -51 c-4 -11 -9 -8 -19 11 -7 14 -16 31 -20 37 -4 7 4 2 19 -11 14 -13
23 -29 20 -37z m30 36 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20
5 0 7 -9 4 -20z m107 -11 c0 -10 -12 -11 -34 -3 -25 10 -19 22 9 16 14 -2 25
-8 25 -13z m145 10 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16 -11z
m-75 -3 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m191 -13 c-6 -11 -6 -24 1 -36 7 -13 7 -17 0 -13 -6 3 -12 3 -15 -1 -2 -5
-2 -2 0 6 2 7 -2 21 -9 29 -8 10 -9 13 -2 9 7 -4 14 0 18 8 3 8 8 15 11 15 3
0 2 -8 -4 -17z m-165 -10 c-15 -4 -17 -11 -13 -37 3 -18 1 -39 -5 -47 -8 -11
-9 -10 -4 5 4 10 2 24 -3 30 -13 17 5 56 26 55 16 -1 16 -2 -1 -6z m-149 -35
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m73 -14 c0 -2 -7 -4 -15
-4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m133 -4 c-3 -12 -8 -19
-11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m-108 0 c-3 -5 -1 -10 4 -10 6 0 11
-5 11 -11 0 -8 -5 -8 -15 1 -17 14 -20 30 -4 30 5 0 7 -4 4 -10z m-90 -10 c-3
-5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m305 -25
c14 -19 14 -19 -2 -6 -10 7 -18 18 -18 24 0 6 1 8 3 6 1 -2 9 -13 17 -24z
m-572 -11 c23 -3 40 -9 37 -13 -2 -5 -17 -6 -32 -4 -16 2 -56 6 -91 10 -34 3
-65 10 -68 15 -5 8 44 5 154 -8z m334 -2 c17 -7 17 -8 -2 -19 -14 -9 -21 -10
-26 -1 -4 7 -3 8 4 4 7 -4 12 -3 12 3 0 5 -8 12 -17 14 -12 3 -13 5 -3 5 8 0
23 -3 32 -6z m-247 -47 c21 -58 65 -101 116 -116 26 -8 35 -15 27 -20 -7 -4
-15 -5 -18 -1 -9 12 -73 32 -83 25 -6 -3 -7 -1 -4 4 4 6 0 17 -9 24 -15 13
-64 105 -64 121 0 23 19 2 35 -37z m297 23 c-7 -7 -12 -8 -12 -2 0 6 3 14 7
17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m29 -5 c-8 -15 -10 -15 -11 -2 0 17 10
32 18 25 2 -3 -1 -13 -7 -23z m20 -9 c-5 -16 -10 -21 -10 -12 -1 18 10 51 15
45 2 -1 -1 -16 -5 -33z m184 26 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2
10 4 10 5 0 13 -4 16 -10z m-80 -13 c12 -10 14 -16 7 -21 -7 -4 -12 -2 -12 3
0 6 -9 11 -21 11 -11 0 -17 5 -14 10 8 13 18 13 40 -3z m-603 -19 c-7 -7 -12
-8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m478 -39 c0 -9 -4 -8 -15 1
-8 7 -15 19 -15 27 0 12 2 12 15 -1 8 -8 15 -20 15 -27z m52 15 c-3 -17 -8
-22 -15 -15 -7 7 -6 15 3 26 17 20 18 19 12 -11z m-556 -19 c-14 -14 -16 -14
-16 -2 0 9 6 17 13 20 19 7 21 -1 3 -18z m704 5 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-164 -20 c8 0 14 -5 14 -10 0
-7 -6 -7 -17 -2 -10 5 -24 7 -32 4 -12 -4 -12 -3 0 11 8 8 16 11 17 6 2 -5 10
-9 18 -9z m-74 3 c-10 -4 -10 -8 -2 -18 7 -8 10 -22 7 -32 -4 -14 -5 -13 -6 5
0 12 -5 22 -10 22 -5 0 -11 7 -15 15 -4 11 1 15 17 14 12 0 16 -3 9 -6z m65
-55 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m860 -5 c2 -6 -5
-13 -15 -15 -15 -4 -18 -1 -14 14 5 19 23 20 29 1z m-1274 0 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m63 -15 c4 -7 3 -8 -4 -4 -7 4 -12 3
-12 -3 0 -6 -8 -7 -17 -4 -17 7 -15 8 24 22 1 1 5 -4 9 -11z m261 -4 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m48 -12 c-3 -3 -11 0 -18 7 -9
10 -8 11 6 5 10 -3 15 -9 12 -12z m81 -32 c2 -20 0 -29 -7 -25 -5 4 -8 12 -6
18 2 7 0 23 -5 37 -7 19 -7 22 3 13 6 -6 13 -26 15 -43z m-629 18 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m133 2 c9 -16 8 -18 -5 -14 -8 4
-15 12 -15 20 0 20 7 17 20 -6z m595 -12 l16 -33 -20 25 c-20 24 -26 40 -16
40 2 0 12 -15 20 -32z m-465 6 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5
9 10 9 6 0 10 -7 10 -16z m290 -2 c0 -10 -36 -9 -46 1 -4 4 5 7 19 7 15 0 27
-4 27 -8z m140 -3 c0 -5 -9 -9 -20 -9 -11 0 -20 4 -20 8 0 4 9 8 20 9 11 1 20
-2 20 -8z m-640 -9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m310 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10
16 6 0 10 -4 10 -9z m234 -7 c9 -24 7 -27 -9 -10 -17 17 -19 26 -6 26 5 0 12
-7 15 -16z m-364 -9 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6
0 10 -7 10 -15z m190 11 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16
-4z m286 -33 c-3 -21 -6 -40 -6 -43 0 -3 -6 4 -12 14 -8 15 -8 21 0 24 7 2 12
12 12 23 0 10 3 19 6 19 3 0 3 -17 0 -37z m-709 5 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m543 12 c0 -5 7 -10 15 -10 9 0 15 -9 14 -22 -1
-17 -2 -19 -6 -5 -2 9 -10 17 -18 17 -7 0 -15 7 -19 15 -3 8 -1 15 4 15 6 0
10 -4 10 -10z m-348 -22 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
-7 -17z m318 12 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m-253 -7 c-4 -3 -1 -13 6 -21 10 -13 9 -14 -12 -3 -19 10 -21
15 -10 22 17 10 25 12 16 2z m223 -9 c0 -3 -7 -12 -15 -21 -20 -19 -32 -10
-14 11 14 15 29 21 29 10z m163 -9 c8 -8 17 -11 21 -8 3 4 6 -1 6 -11 0 -9 -4
-15 -9 -12 -5 4 -11 3 -14 -1 -2 -5 -2 -2 -1 4 2 7 -9 19 -24 27 -18 10 -22
15 -10 16 9 0 23 -7 31 -15z m-433 -45 c0 -27 -4 -50 -10 -50 -11 0 -13 42 -4
78 10 35 14 26 14 -28z m100 35 c7 -9 8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4
15 -2 15 2 0 9 -7 16 -15z m50 6 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9
5 16 10 16 6 0 10 -4 10 -9z m-260 -26 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m437 -22 c-3 -10 -5 -4 -5 12 0 17 2 24
5 18 2 -7 2 -21 0 -30z m73 21 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15
11 15 -7z m-233 -31 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-217 12 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10
-15z m-75 -17 c6 -4 2 -8 -7 -8 -10 0 -18 7 -18 17 0 9 3 12 8 7 4 -5 12 -12
17 -16z m45 12 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m214 1 c3 -5 0 -11 -7 -14 -8 -3 -14 1 -14 9 0 16 13 19 21 5z m66 -28
c0 -7 -8 -13 -17 -13 -13 0 -14 3 -6 11 6 6 9 19 6 28 -4 14 -3 14 6 1 6 -8
11 -21 11 -27z m30 27 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m154 -23 c7 -6 4 -7 -7 -3 -10 3 -23 6 -28 6 -5 0 -4 6 3 14 9
10 14 11 17 3 2 -7 9 -16 15 -20z m-81 -4 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m-289 -266 l58 -2 38 -100 c162 -433 463 -746 834 -870 106
-35 287 -59 398 -51 117 8 297 47 388 86 94 39 204 106 300 183 83 68 77 55
-18 -37 -173 -165 -440 -320 -612 -352 -8 -2 -46 -10 -85 -18 -74 -16 -305
-23 -370 -10 -24 4 -40 14 -45 28 -5 11 -9 15 -9 9 -1 -7 -9 -13 -18 -13 -11
0 -14 3 -7 8 6 4 14 17 18 28 5 17 1 23 -16 28 l-23 7 23 16 c12 8 22 22 22
30 0 8 4 12 9 8 6 -3 14 3 20 13 8 15 4 21 -25 34 -19 9 -34 12 -34 8 0 -5 12
-13 28 -18 25 -8 24 -9 -15 -9 -24 0 -43 3 -43 8 0 5 8 6 18 3 13 -4 14 -3 4
4 -8 5 -13 13 -12 18 1 5 -4 10 -11 13 -6 2 -9 -1 -6 -7 4 -5 0 -27 -8 -48
-12 -31 -15 -33 -11 -11 3 21 0 27 -11 23 -9 -4 -13 -1 -11 6 3 7 10 13 16 13
7 0 12 3 12 8 0 4 0 11 0 15 0 12 -78 54 -88 48 -4 -2 -14 0 -22 6 -12 8 -12
9 3 4 27 -9 20 2 -14 19 -17 9 -33 14 -37 11 -3 -3 0 -6 7 -6 7 0 9 -4 6 -10
-4 -6 1 -7 12 -3 10 3 15 3 11 -2 -4 -4 -3 -14 4 -22 9 -11 7 -15 -10 -20 -12
-3 -22 -12 -22 -20 0 -12 3 -13 14 -4 17 14 57 -2 55 -22 0 -9 -2 -9 -6 1 -7
17 -33 15 -33 -3 0 -8 -6 -15 -12 -15 -10 0 -10 -2 0 -8 20 -13 10 -96 -12
-100 -13 -3 -16 0 -11 9 5 8 4 10 -3 5 -14 -8 -182 81 -182 97 0 4 -7 7 -15 7
-18 0 -20 16 -2 24 6 3 3 4 -9 1 -15 -4 -41 12 -97 60 -78 67 -87 79 -64 89 6
3 2 3 -9 1 -16 -4 -26 2 -40 22 -10 16 -17 34 -16 42 2 7 -1 10 -8 6 -12 -7
-47 33 -42 48 1 4 -4 7 -11 7 -13 0 -53 59 -49 72 1 4 -7 12 -18 18 -11 6 -20
15 -20 21 0 6 4 7 10 4 6 -3 7 1 4 9 -3 9 -10 13 -15 10 -5 -3 -12 3 -16 13
-3 10 -14 28 -24 41 -10 13 -15 30 -12 39 4 10 2 14 -4 10 -5 -3 -19 14 -31
38 -14 29 -18 45 -10 45 7 0 4 6 -6 13 -10 8 -18 26 -18 40 0 15 -6 27 -12 27
-18 0 -35 74 -20 88 6 6 14 10 16 9 2 -1 -2 3 -9 9 -7 6 -13 16 -13 23 0 6 -8
19 -18 29 -10 10 -25 43 -34 74 l-16 56 57 -5 c31 -2 82 -5 115 -6z m299 -143
c85 -130 239 -287 361 -368 192 -127 365 -184 584 -193 146 -6 221 3 397 47
91 23 113 26 130 15 11 -6 28 -11 38 -9 9 1 17 -1 17 -6 0 -4 27 -10 59 -14
33 -4 61 -11 63 -17 2 -6 15 -1 29 10 20 15 25 27 22 48 -4 22 -2 25 6 13 7
-9 11 -10 11 -3 0 6 -4 15 -10 18 -12 8 -8 107 5 120 6 6 35 30 65 54 82 63
258 241 273 274 11 28 14 29 62 23 28 -3 63 -6 78 -6 l28 0 -37 -57 c-185
-292 -417 -513 -673 -641 -50 -25 -76 -33 -78 -25 -2 7 -11 9 -19 6 -8 -3 -14
-1 -14 4 0 5 -17 9 -37 8 -21 -1 -63 5 -93 12 -30 8 -72 16 -93 18 -21 1 -55
6 -75 10 -20 4 -38 7 -39 6 -2 0 -21 6 -43 14 -25 9 -42 10 -46 4 -4 -6 -11
-6 -20 2 -8 6 -24 9 -36 6 -12 -4 -27 -3 -32 2 -6 4 -20 11 -31 13 -11 3 -29
9 -40 13 -16 5 -17 5 -5 -4 13 -9 13 -11 -2 -11 -10 0 -18 7 -18 15 0 9 -9 15
-22 14 -22 0 -22 -1 -3 -10 14 -7 9 -8 -20 -3 -22 3 -42 9 -45 14 -3 4 -14 10
-25 12 -11 1 -22 13 -25 26 -4 17 -18 28 -55 42 -66 23 -145 64 -228 117 -57
37 -71 42 -90 33 -20 -10 -20 -10 -4 3 21 17 22 31 2 23 -8 -3 -14 -2 -13 2 2
4 -43 52 -98 107 -55 55 -115 120 -132 145 -82 119 -130 199 -124 205 4 4 11
-1 17 -10 5 -10 40 -64 78 -121z m-416 -36 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m1056 -23 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13
8 -4z m-213 -47 c11 -12 20 -35 20 -53 0 -33 -24 -75 -42 -75 -7 0 -4 6 6 13
13 10 17 24 14 57 -3 38 -6 45 -23 42 -16 -2 -17 -1 -5 7 17 12 17 12 -31 15
l-36 3 1 -56 c1 -50 3 -56 24 -59 36 -5 15 -22 -29 -22 -32 0 -39 4 -39 19 0
10 6 21 14 24 7 3 14 17 14 32 0 20 -4 25 -17 21 -13 -5 -13 -4 2 8 9 7 17 19
17 27 0 11 -2 11 -10 -1 -8 -13 -10 -12 -10 3 0 14 9 17 55 17 43 0 58 -5 75
-22z m290 -24 c0 -14 -4 -23 -9 -20 -5 3 -12 -1 -15 -10 -4 -9 -9 -14 -13 -11
-3 4 0 13 7 22 7 8 10 22 6 30 -3 9 0 15 9 15 9 0 15 -10 15 -26z m270 -35 c0
-22 7 -42 17 -50 10 -8 14 -9 10 -2 -4 6 -3 14 2 17 5 4 12 24 16 46 11 62 45
49 45 -18 0 -15 4 -31 8 -37 10 -14 35 -18 27 -5 -4 6 -3 16 2 23 5 7 9 27 9
45 -1 24 3 32 17 32 9 0 17 -3 17 -7 0 -22 -44 -153 -51 -153 -5 0 -9 5 -9 11
0 5 -4 8 -9 4 -5 -3 -17 21 -27 52 l-17 58 -10 -55 c-10 -60 -27 -90 -27 -49
-1 21 -2 22 -11 6 -6 -9 -15 -15 -21 -11 -6 4 -5 10 3 15 11 7 11 9 1 9 -9 0
-11 7 -7 21 5 16 4 19 -6 14 -9 -6 -11 -2 -7 13 4 12 2 22 -3 22 -5 0 -9 7 -9
16 0 9 5 12 13 8 9 -6 9 -4 -1 8 -10 13 -10 17 0 21 18 6 28 -12 28 -54z
m-386 25 c12 -5 16 -21 16 -67 0 -33 -3 -57 -7 -53 -4 3 -6 26 -4 50 2 44 -8
60 -44 69 -19 4 -19 5 2 6 12 0 28 -2 37 -5z m133 -16 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m752 -10 c0 -18 -4 -55 -7 -82 -6 -45 -8 -48
-21 -32 -12 14 -12 24 -4 46 6 16 8 31 5 35 -11 10 -12 46 -2 40 5 -3 10 -2
10 4 0 5 -6 12 -12 14 -7 3 -3 6 10 6 19 1 22 -4 21 -31z m161 12 c0 -9 -3
-45 -6 -79 -5 -46 -10 -59 -19 -51 -6 5 -16 6 -20 2 -5 -4 -5 1 -2 11 4 10 7
24 7 30 0 7 5 6 11 -3 9 -13 10 -13 7 0 -3 8 -21 26 -42 40 -21 13 -33 26 -27
28 5 2 22 -5 37 -15 26 -17 28 -17 21 0 -3 9 -2 17 2 17 5 0 7 8 4 18 -3 12 1
19 11 19 9 0 16 -8 16 -17z m-766 4 c12 -5 16 -20 15 -68 0 -33 -3 -55 -6 -48
-2 6 -11 12 -19 12 -8 0 -14 4 -14 10 0 5 8 7 18 3 16 -6 16 -5 0 13 -12 13
-14 24 -8 34 14 22 12 27 -12 34 -15 4 -27 0 -35 -10 -10 -14 -12 -14 -10 5 1
16 8 21 28 21 15 0 34 -3 43 -6z m-164 -48 c0 -19 5 -36 12 -38 6 -2 9 -9 7
-15 -3 -8 -11 -6 -25 6 -16 13 -20 25 -16 49 6 43 22 41 22 -2z m564 21 c13
-9 17 -23 14 -51 -3 -39 -29 -68 -45 -52 -4 4 0 12 10 17 20 12 23 62 5 76 -7
6 -21 10 -30 10 -22 0 -34 -52 -17 -73 15 -19 4 -31 -15 -15 -22 19 -27 64 -8
83 20 20 62 23 86 5z m86 -2 c0 -8 7 -15 15 -15 8 0 15 5 15 10 0 6 5 10 10
10 6 0 10 -9 10 -21 0 -15 -5 -19 -19 -14 -20 6 -31 -16 -31 -63 0 -21 -2 -22
-15 -8 -17 17 -20 62 -5 71 8 5 8 11 1 19 -6 7 -8 16 -4 19 11 12 23 8 23 -8z
m-553 -37 c-4 -12 -1 -28 5 -35 7 -9 7 -13 0 -13 -6 0 -14 5 -17 10 -4 6 -11
7 -17 4 -6 -4 -6 2 1 15 7 11 8 21 4 21 -7 0 18 20 26 20 2 0 1 -10 -2 -22z
m745 -18 c-3 -18 0 -30 7 -30 6 0 11 -7 11 -15 0 -18 -11 -19 -32 -2 -21 17
-27 38 -17 64 12 33 36 19 31 -17z m-1028 5 c3 -9 0 -15 -9 -15 -8 0 -15 7
-15 15 0 8 4 15 9 15 5 0 11 -7 15 -15z m1186 -43 c0 -24 -8 -32 -26 -25 -22
8 -17 43 6 43 13 0 20 -7 20 -18z m-1250 -602 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-30 -20 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-24 -42 c2 -16 -24 -34 -34
-23 -3 3 -1 5 5 5 6 0 9 9 6 21 -4 15 -2 20 7 17 7 -2 14 -11 16 -20z m56 -8
c10 0 18 -6 18 -12 0 -8 8 -4 16 7 11 14 18 16 21 8 3 -7 11 -13 19 -13 8 0
14 -4 14 -10 0 -13 -23 -13 -37 1 -8 8 -13 5 -18 -11 -5 -17 -9 -19 -15 -10
-5 7 -24 14 -43 14 -21 1 -33 5 -29 11 4 5 7 13 8 18 1 4 2 10 3 14 0 4 6 2
13 -5 7 -7 20 -12 30 -12z m1278 -205 c73 -38 109 -211 89 -425 -12 -125 -42
-218 -87 -271 -34 -39 -38 -41 -79 -36 -47 5 -56 13 -97 90 -32 59 -51 176
-38 232 6 22 10 33 11 23 1 -13 7 -15 29 -9 26 7 27 10 29 79 2 69 2 71 -17
59 -22 -13 -27 -43 -7 -50 6 -2 0 -6 -15 -9 -16 -3 -28 -1 -28 3 0 5 5 9 11 9
8 0 9 11 5 33 -4 23 -3 28 4 17 7 -11 10 -5 11 25 1 49 14 88 22 65 3 -8 7
-24 9 -35 2 -11 9 3 14 32 6 29 7 50 2 47 -4 -3 -8 -13 -8 -22 -1 -16 -1 -16
-10 0 -22 36 60 158 106 158 8 0 28 -7 44 -15z m-2193 -22 c29 -19 47 -48 66
-108 19 -63 31 -155 19 -155 -5 0 -12 27 -16 60 -4 33 -13 75 -20 92 -7 18
-16 42 -20 53 -6 16 -9 17 -17 5 -9 -13 -10 -12 -5 2 6 20 -37 58 -65 58 -11
0 -19 5 -19 10 0 13 47 2 77 -17z m2749 -158 c-3 -99 -9 -215 -13 -259 l-6
-79 68 8 68 7 -6 -69 c-4 -37 -7 -80 -7 -94 l0 -27 -99 -1 c-55 -1 -102 2
-105 7 -6 10 21 668 28 680 2 4 21 8 42 8 l37 0 -7 -181z m364 160 c38 -35 55
-117 55 -270 -1 -162 -21 -269 -62 -333 -32 -48 -63 -62 -144 -62 l-59 0 0 63
c0 34 4 148 10 252 5 105 10 228 11 275 1 47 4 89 7 94 2 4 40 6 83 4 58 -3
84 -10 99 -23z m-3709 -12 c18 -15 40 -45 50 -68 22 -51 36 -155 20 -155 -6 0
-11 17 -11 41 0 22 -4 38 -9 35 -5 -4 -7 2 -4 12 5 22 -28 102 -54 124 -10 10
-37 22 -59 27 l-39 9 37 1 c26 1 46 -7 69 -26z m1789 -75 c0 -94 -1 -101 -16
-86 -14 14 -15 25 -4 88 7 39 9 74 6 77 -3 4 -6 -3 -6 -15 0 -12 -3 -22 -8
-22 -4 0 -8 -23 -8 -52 -1 -32 -6 -54 -14 -56 -9 -3 -9 -6 1 -13 9 -6 -5 -7
-36 -3 -30 3 -58 13 -70 25 -17 17 -18 19 -2 19 9 0 17 -5 17 -11 0 -5 5 -7
10 -4 19 12 -10 36 -34 29 -20 -6 -20 -5 -5 11 13 13 21 15 37 6 36 -19 37
-12 3 18 -20 18 -28 32 -21 36 6 4 17 1 25 -5 8 -7 15 -8 15 -3 0 25 -43 43
-108 45 -37 1 -4 5 73 8 77 3 141 7 143 8 1 1 2 -44 2 -100z m265 -269 c24
-200 41 -366 39 -369 -3 -2 -9 2 -14 10 -6 8 -10 11 -11 5 0 -5 -7 4 -15 20
l-15 30 6 -28 c5 -23 3 -28 -11 -25 -10 2 -19 9 -22 17 -3 8 0 11 7 7 7 -5 9
-1 5 9 -4 8 -1 21 5 29 16 19 0 24 -84 22 l-70 -1 -3 -30 c-4 -50 -12 -75 -22
-75 -6 0 -10 9 -10 20 0 24 -6 25 -26 5 -19 -19 -41 -21 -26 -2 5 6 12 55 15
107 3 62 16 135 37 210 17 63 34 139 37 168 3 33 10 52 18 52 7 0 15 14 19 32
8 43 0 48 -13 7 l-11 -34 5 35 c3 19 10 36 15 38 5 2 6 10 3 18 -3 8 -2 30 1
49 6 32 10 34 49 37 23 2 43 2 45 2 2 -1 23 -165 47 -365z m169 355 c3 -9 6
-48 6 -88 0 -136 13 -345 21 -340 5 2 11 20 15 39 3 19 10 32 15 29 5 -3 9 1
9 10 0 8 -5 16 -11 18 -8 3 -7 8 2 18 9 10 15 45 17 100 2 47 9 117 15 155 12
68 13 70 43 73 l31 3 6 -113 c4 -62 7 -156 7 -208 0 -106 11 -141 28 -91 11
32 26 40 36 19 3 -7 3 -3 0 9 -7 29 5 50 18 32 6 -10 7 -7 3 9 -4 13 -2 22 4
22 6 0 11 7 11 15 0 23 -18 18 -26 -7 -3 -12 -13 -28 -20 -35 -10 -11 -12 -7
-9 19 2 18 7 69 10 113 3 44 8 94 12 110 3 17 6 47 7 68 1 37 1 37 44 37 37 0
43 -3 38 -17 -4 -14 -82 -553 -98 -681 -2 -12 -7 -22 -13 -22 -5 1 -9 9 -7 18
2 14 1 14 -7 3 -5 -8 -20 -16 -33 -18 -23 -3 -24 1 -36 89 -15 112 -16 244 -2
253 6 3 10 13 10 21 0 8 -4 13 -9 10 -5 -4 -7 3 -4 14 3 10 -2 26 -10 35 -14
13 -16 8 -22 -53 -4 -37 -15 -136 -26 -220 -10 -84 -19 -157 -19 -162 0 -6 -4
-10 -10 -10 -5 0 -10 7 -10 16 0 11 -5 14 -17 9 -42 -17 -50 -16 -56 4 -3 14
0 21 9 21 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10
10 0 15 9 14 28 -2 23 -2 24 -6 4 -4 -19 -6 -20 -19 -7 -8 8 -20 12 -28 9 -7
-3 -18 2 -25 9 -12 16 -35 239 -28 269 4 15 0 102 -16 315 -4 52 -4 52 27 56
17 2 34 5 37 5 4 1 10 -5 13 -14z m928 5 c32 -17 48 -71 48 -159 0 -88 -15
-163 -35 -180 -10 -8 -9 -18 6 -47 16 -31 19 -60 19 -171 0 -118 -2 -133 -16
-128 -9 3 -27 6 -40 6 -24 0 -24 1 -24 103 0 115 -7 133 -56 139 l-32 4 -7
-133 c-4 -73 -11 -133 -16 -133 -5 0 -9 4 -9 8 0 5 -14 6 -31 4 l-31 -5 6 149
c3 82 8 240 12 352 l7 202 89 0 c49 0 98 -5 110 -11z m-2186 -5 c26 -4 53 -16
68 -31 108 -108 90 -583 -27 -700 -31 -31 -34 -32 -52 -17 -19 15 -19 15 -1
10 24 -8 57 25 84 84 39 86 47 144 46 330 0 142 -14 230 -35 230 -6 0 -8 4 -4
9 11 19 -40 63 -83 71 -35 6 -42 5 -43 -9 0 -12 -2 -11 -9 4 -6 13 -9 15 -9 5
-1 -9 -4 -11 -9 -5 -9 13 -62 16 -62 3 0 -5 -5 -6 -10 -3 -6 3 -14 -4 -19 -17
-9 -22 -10 -21 -10 8 l-1 31 67 2 c36 1 86 -1 109 -5z m414 -8 c0 -8 20 -173
45 -367 46 -365 49 -386 40 -377 -8 8 -23 101 -40 243 -20 164 -20 163 -27
171 -4 3 -4 12 -2 19 5 11 -19 224 -32 288 l-6 27 -19 -22 -19 -23 -6 22 c-3
12 -13 24 -23 26 -9 3 8 5 37 6 38 1 52 -3 52 -13z m-875 -45 c-15 -15 -16
-15 -9 2 5 14 2 16 -12 10 -16 -6 -17 -5 -4 9 11 11 17 12 22 4 5 -7 8 -3 9 9
1 18 2 19 6 1 3 -10 -3 -26 -12 -35z m1105 25 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-770 -16 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m157 -2 c0 -7 4 -21 8 -30 5
-12 3 -18 -6 -18 -7 0 -9 3 -6 6 3 4 2 14 -3 24 -5 10 -6 20 -3 24 9 8 10 8
10 -6z m-1027 -4 c0 -8 -19 -13 -24 -6 -3 5 1 9 9 9 8 0 15 -2 15 -3z m920 -4
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m403 -32 c6 -4 8 -10 4 -15 -5 -4 -12 -1 -17 7 -13 21 -13 -20 0 -54 5 -14 7
-26 5 -26 -14 0 -25 58 -19 95 5 27 9 33 12 20 2 -11 9 -23 15 -27z m-693 -4
c0 -7 -7 -15 -16 -18 -14 -5 -15 -4 -5 8 6 7 9 22 5 32 -5 16 -4 16 5 4 6 -8
11 -20 11 -26z m720 15 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m16 -27 c-11 -10 -15 4 -8 28 l7 25 3 -23 c2 -13 1 -26 -2
-30z m-918 -8 c-3 -14 -5 -12 -10 9 -3 19 -2 25 4 19 6 -6 8 -18 6 -28z m132
5 c0 -4 -7 -10 -16 -13 -23 -9 -31 -7 -14 4 8 5 11 12 7 16 -3 4 -13 1 -22 -6
-10 -8 -15 -9 -15 -1 0 6 5 11 11 11 5 0 7 5 4 10 -4 6 5 5 19 -2 14 -6 26
-15 26 -19z m400 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m-124 -16 c-3 -8 -2 -23 4 -33 7 -15 4 -20 -15 -29 -15 -7
-25 -8 -25 -2 0 6 7 10 16 10 12 0 15 6 10 25 -4 14 -4 30 -1 35 10 16 18 11
11 -6z m89 -13 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11 -17z m410
-17 c1 -14 -5 -24 -13 -24 -9 0 -12 6 -8 16 3 9 6 23 6 31 0 26 15 4 15 -23z
m-746 -21 c2 -2 -3 -3 -11 -3 -9 0 -19 7 -22 16 -6 15 -4 15 11 3 10 -7 20
-15 22 -16z m415 12 c3 -8 1 -15 -3 -15 -5 0 -7 -10 -4 -22 4 -21 4 -21 -9 2
-8 14 -10 27 -5 29 5 2 3 7 -4 12 -11 7 -11 9 2 9 9 0 19 -7 23 -15z m36 5 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m233
-79 c-3 -14 0 -27 8 -33 12 -8 11 -9 -1 -5 -11 3 -15 20 -16 68 -1 53 0 58 7
29 4 -19 5 -46 2 -59z m-1396 40 c-17 -45 1 -69 84 -116 62 -36 78 -51 99 -91
22 -42 25 -62 25 -144 0 -114 -22 -195 -66 -245 -30 -33 -59 -49 -42 -22 4 6
8 17 8 22 0 6 7 10 15 10 52 0 90 239 54 347 -16 48 -65 98 -112 112 -12 4
-36 19 -52 33 -25 20 -30 32 -30 67 0 41 5 56 21 56 4 0 2 -13 -4 -29z m606 8
c-1 -8 -9 -34 -18 -57 -8 -24 -18 -92 -22 -151 -7 -128 7 -201 44 -231 l24
-19 -35 2 c-19 2 -38 6 -43 10 -4 5 0 5 10 1 21 -8 21 -6 3 37 -34 83 -26 298
13 379 16 33 17 41 5 36 -9 -3 -12 -2 -9 5 9 14 30 5 28 -12z m379 -11 c-8 -8
-9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m827 22 c12 0 13 -32 7 -197
-8 -240 -17 -376 -24 -368 -3 3 -5 29 -4 58 1 38 -1 47 -8 32 -7 -17 -9 -18
-9 -2 -1 9 -8 17 -16 17 -8 0 -20 10 -26 22 -15 28 -23 88 -12 88 4 0 8 33 8
73 0 48 5 80 14 90 17 22 28 21 34 0 3 -11 6 -6 7 12 2 30 2 30 10 5 5 -17 8
-5 7 40 0 36 -3 55 -5 43 -2 -14 -8 -20 -18 -16 -11 4 -12 9 -4 18 8 10 6 16
-7 25 -15 10 -14 10 10 5 15 -3 27 -3 27 1 0 12 -28 33 -35 26 -5 -4 -5 -1 0
7 4 8 2 17 -6 23 -10 7 -7 8 11 4 14 -3 31 -6 39 -6z m-804 -210 c-6 -151 -4
-200 4 -200 7 0 10 -4 6 -10 -3 -5 -11 -7 -18 -4 -9 3 -11 51 -10 187 2 100 5
192 8 205 14 57 17 6 10 -178z m-494 163 c-8 -15 -10 -15 -11 -2 0 17 10 32
18 25 2 -3 -1 -13 -7 -23z m918 -60 c-19 -131 -41 -243 -49 -248 -9 -5 -4 42
10 105 5 25 12 63 14 85 2 22 7 48 11 58 4 9 5 23 2 31 -3 8 -3 17 0 20 16 16
19 1 12 -51z m-914 19 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m439 -65 c7 -6 6 -7 -4 -4 -8 2 -16 12 -17 23 -2 13 -1 15 4 4 3 -8 11 -19 17
-23z m414 -12 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m1349 3
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m43 -9 c0 -4 -7 -9 -15
-13 -9 -3 -15 0 -15 8 0 7 7 13 15 13 8 0 15 -4 15 -8z m-1943 -106 c-4 -54
-7 -151 -7 -215 0 -68 -4 -118 -9 -118 -6 0 -9 85 -8 215 2 177 4 215 16 215
12 0 13 -17 8 -97z m-40 81 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m390 -26 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m170 0 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-592 2 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m9 -36 c3 -8 2 -12 -4 -9
-6 3 -10 10 -10 16 0 14 7 11 14 -7z m611 -4 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m2631 -42 c-3 -13 -6 -58 -6 -100 l0
-78 -35 0 -35 0 0 100 0 100 41 0 c38 0 41 -2 35 -22z m-3221 2 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m562 -47 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m122 0 c3 -2 -9 -3 -27 -1 -18 1
-32 6 -32 10 0 8 49 0 59 -9z m-27 -60 c-5 -10 -7 -24 -5 -31 3 -8 -1 -10 -11
-6 -21 8 -20 31 1 43 28 15 10 22 -19 7 -24 -13 -26 -12 -25 5 1 14 7 17 24
13 15 -4 23 -2 23 7 1 10 4 8 11 -3 6 -11 6 -24 1 -35z m-79 7 c-3 -12 -8 -19
-11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m-976 -57 c-4 -38 -7 -92 -7 -122 0
-30 -3 -52 -6 -48 -4 3 -6 23 -6 44 1 174 3 193 13 193 9 0 10 -20 6 -67z
m-480 51 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m40 0 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m43 -18 c0 -15 11 -32 28 -45
17 -13 21 -20 12 -21 -24 0 -38 13 -55 53 -14 33 -14 37 0 37 9 0 15 -9 15
-24z m1347 2 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-217 2 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m350 -10
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-473 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m400 16 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-294 -26 c5 -20 4 -21 -6
-7 -7 9 -21 19 -32 22 -16 4 -14 5 6 6 20 1 28 -5 32 -21z m-353 2 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m271 -41 c-8
-38 -9 -40 -10 -12 -1 17 2 39 5 48 11 29 14 10 5 -36z m116 16 c-16 -17 -17
-14 -10 14 4 15 8 19 13 10 5 -6 3 -18 -3 -24z m-174 -28 c11 -9 18 -20 15
-23 -3 -3 -13 2 -21 11 -14 14 -16 13 -20 -7 -3 -17 -5 -15 -6 9 0 17 -4 40
-7 50 -5 15 -4 15 7 -2 7 -11 21 -28 32 -38z m111 45 c2 -4 -4 -9 -15 -9 -11
-1 -17 2 -14 8 7 11 22 12 29 1z m271 -2 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9
10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-38 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m62 -40 c-4 -24 -8 -47 -9 -52 0 -6 -5 -5 -12 2 -7 7
-21 12 -32 12 -23 0 -16 10 11 14 11 2 21 16 27 39 13 50 25 37 15 -15z m-209
38 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m177 -28 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-270 -22 c-6
-15 -67 -49 -75 -41 -11 10 22 35 40 30 14 -5 20 0 25 27 5 21 8 27 11 15 2
-10 1 -24 -1 -31z m48 10 c0 -28 -10 -26 -18 4 -3 11 0 20 6 20 7 0 12 -11 12
-24z m-768 -8 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m423 6 c0
-8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m227 -6
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m323 18 c0 -11 -19 -15
-25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-630 -16 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1080 -10 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1645 -20 c10 -11 16
-20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m595 6
c0 -15 -42 -66 -54 -66 -3 0 -6 7 -6 15 0 8 4 15 10 15 5 0 8 6 6 13 -1 6 -1
9 1 5 8 -14 21 -8 26 12 6 23 17 27 17 6z m-67 -17 c-8 -8 -13 -7 -18 2 -5 8
-4 10 3 5 7 -4 12 -2 12 5 0 7 3 9 7 6 4 -4 2 -12 -4 -18z m-545 -14 c-3 -3
-9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m4498 -69 c-6 -25 -9 -26 -84
-26 -77 0 -101 -2 -407 -20 -88 -5 -239 -12 -335 -15 -96 -4 -212 -8 -257 -11
-62 -3 -83 -1 -83 8 0 9 -3 10 -8 2 -7 -11 -113 -22 -306 -31 -103 -4 -145 3
-166 28 -3 4 -14 3 -25 -2 -19 -7 -18 -8 4 -8 19 -1 21 -4 12 -15 -14 -17
-126 -31 -170 -21 -33 7 -42 24 -13 26 16 1 16 2 0 6 -51 13 7 21 250 32 147
7 332 17 412 22 80 5 363 20 630 34 267 14 496 26 510 28 34 4 44 -7 36 -37z
m-2016 -77 c-63 -10 -251 -17 -243 -9 5 5 165 16 248 17 l50 1 -55 -9z m93 4
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-423 -17 c0 -2 -7 -6
-15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m67 -2 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-224 -11 c-13 -2 -35 -2 -50
0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m-195 -10 c-10 -2 -26 -2 -35 0 -10 3 -2
5 17 5 19 0 27 -2 18 -5z m-241 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m76 -1 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z
m-233 -7 c0 -2 -18 -6 -40 -8 -22 -2 -40 0 -40 4 0 5 18 8 40 8 22 0 40 -2 40
-4z m-247 -13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m65 0
c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m2190 -36 c14 -17 13
-217 -1 -242 -7 -11 -19 -21 -27 -23 -13 -2 -12 0 1 10 18 15 32 77 23 102 -3
9 -21 23 -40 32 -26 12 -34 23 -34 42 0 29 25 62 49 63 10 0 12 2 4 6 -7 2
-13 9 -13 14 0 13 26 11 38 -4z m-2941 -3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m2813 -97 c-12 -34 -24 -88 -27 -119 -3 -32 -10 -58 -15
-58 -12 0 0 113 17 164 17 48 11 70 -16 55 -25 -13 -39 -4 -39 26 0 21 5 24
48 27 58 4 62 -7 32 -95z m-3002 86 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22
1 32 -1 23 -4z m2862 -55 c0 -96 -12 -228 -20 -223 -4 2 -6 33 -4 67 1 35 4
98 5 141 3 92 19 104 19 15z m-3103 46 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m2861 -27 c18 -37 11 -55 -11 -32 -24 24 -45 12 -53 -30 -8
-43 -2 -52 19 -30 23 22 34 19 46 -17 16 -46 -5 -148 -31 -148 -5 0 -2 10 6
22 9 12 16 41 16 64 0 35 -4 43 -22 47 -29 8 -39 -9 -36 -55 5 -55 -7 -46 -20
15 -14 64 -9 104 18 158 24 47 48 49 68 6z m133 12 c6 -12 8 -37 5 -58 -4 -20
-6 -45 -6 -56 3 -107 1 -118 -19 -139 l-21 -20 0 29 c0 24 2 27 11 15 9 -12
10 -11 6 8 -3 12 -1 20 4 16 14 -8 11 62 -3 74 -20 16 -32 15 -39 -5 -11 -27
-10 -73 1 -73 6 0 10 -7 10 -15 0 -9 -9 -15 -22 -15 -18 0 -20 2 -8 10 12 8
12 10 -2 15 -10 4 -15 14 -12 26 2 10 6 28 8 39 3 11 8 25 11 30 4 6 2 16 -3
23 -15 18 -6 76 14 97 25 25 51 25 65 -1z m-403 -33 c11 -21 12 -38 4 -68 -7
-27 -7 -49 0 -70 11 -31 -3 -101 -22 -113 -6 -4 -6 4 0 20 17 45 10 122 -11
110 -4 -3 -2 7 6 22 19 35 20 87 3 77 -10 -5 -10 -4 0 7 10 11 8 17 -10 32
-21 17 -21 18 -3 15 11 -2 26 -16 33 -32z m118 -18 c-3 -29 -6 -93 -6 -142 0
-49 -3 -86 -7 -82 -5 4 -8 22 -9 39 -1 105 -4 119 -24 124 -27 7 -25 33 5 62
14 13 25 29 25 37 0 8 5 14 11 14 8 0 10 -16 5 -52z m-258 -43 c-2 -47 -1 -85
4 -85 4 0 8 -15 7 -32 -1 -30 -2 -30 -8 -9 -4 14 -13 27 -21 29 -12 4 -13 2
-2 -9 6 -7 12 -16 12 -20 0 -10 -54 3 -62 15 -5 7 -8 3 -8 -9 -1 -18 -2 -17
-11 4 -11 28 3 82 27 102 13 11 13 6 -1 -29 -15 -37 -15 -43 -2 -57 20 -19 27
-19 27 0 0 8 5 15 11 15 5 0 9 8 8 18 -5 55 2 152 12 152 8 0 10 -26 7 -85z
m-164 -135 l-8 -95 -7 60 c-4 33 -3 83 2 110 6 28 9 66 8 85 -2 61 5 57 9 -5
2 -33 0 -103 -4 -155z m-113 179 c6 -12 8 -39 5 -63 -3 -22 -6 -50 -5 -61 4
-107 3 -115 -15 -132 -18 -17 -18 -17 -7 3 15 31 18 87 4 91 -7 3 -9 11 -6 20
4 11 2 14 -5 9 -17 -10 -15 9 3 24 9 8 15 29 15 56 0 33 -5 46 -22 58 l-22 16
21 0 c12 0 27 -9 34 -21z m-145 -21 c30 -59 2 -268 -37 -268 -8 0 -8 4 1 15 7
8 9 15 4 15 -5 0 -3 4 5 9 10 7 15 38 18 113 3 79 1 100 -7 88 -10 -13 -11
-12 -6 4 7 21 -11 49 -27 39 -6 -3 -7 -1 -3 5 13 21 35 13 52 -20z m-320 8
c16 -12 20 -29 23 -106 3 -101 -15 -172 -50 -190 -15 -8 -17 -7 -13 5 4 8 10
15 14 15 15 0 40 94 40 151 0 63 -23 125 -49 132 -14 4 -14 5 0 6 8 0 24 -5
35 -13z m-160 -57 c-3 -34 -6 -104 -6 -155 0 -52 -4 -94 -8 -94 -5 0 -8 70 -8
155 1 125 4 155 15 155 11 0 13 -13 7 -61z m555 11 c-1 -22 2 -40 8 -40 5 0 9
-9 8 -20 -1 -11 -4 -20 -8 -20 -15 0 -21 -61 -7 -77 11 -14 11 -16 -1 -11 -20
6 -33 58 -19 75 6 7 7 13 2 13 -5 0 -2 4 6 10 12 7 12 13 1 33 -8 16 -10 33
-5 48 4 13 7 29 5 34 -1 6 1 6 5 2 5 -4 7 -26 5 -47z m421 24 c-3 -16 1 -29 9
-35 7 -4 11 -11 7 -14 -3 -3 -13 1 -22 10 -20 20 -21 65 -2 65 9 0 12 -8 8
-26z m-1055 -70 c25 -101 15 -100 -16 3 -24 80 -24 83 -13 83 4 0 17 -39 29
-86z m-372 56 c20 -22 37 -98 24 -106 -5 -3 -9 7 -9 21 0 33 -16 65 -44 87
-17 14 -18 18 -5 18 9 0 24 -9 34 -20z m192 -42 c-2 -13 -4 -3 -4 22 0 25 2
35 4 23 2 -13 2 -33 0 -45z m668 -53 c0 -97 -9 -115 -20 -42 -3 23 -9 51 -11
62 -4 17 -3 17 6 5 9 -12 11 -11 9 10 -2 45 1 73 8 66 4 -4 8 -49 8 -101z
m-1050 -22 c10 -65 21 -136 26 -158 8 -38 5 -54 -6 -43 -3 3 -15 66 -26 139
-11 74 -24 144 -29 157 -7 16 -6 22 4 22 9 0 18 -35 31 -117z m-182 100 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m47 -33 c0 -22 -2 -40 -4
-40 -2 0 -6 18 -8 40 -2 22 0 40 4 40 5 0 8 -18 8 -40z m2542 -11 c3 -30 1
-46 -6 -46 -13 0 -18 41 -10 76 7 31 10 25 16 -30z m-2723 -86 c0 -71 -4 -146
-9 -168 -6 -28 -8 11 -7 127 1 93 5 168 10 168 4 0 7 -57 6 -127z m1041 101
c-14 -36 -12 -128 3 -143 10 -11 9 -13 -3 -9 -8 3 -18 18 -21 34 -8 36 1 115
14 132 15 19 17 14 7 -14z m-1278 -1 c29 -33 41 -93 34 -164 -7 -77 -28 -129
-60 -149 -20 -12 -20 -12 -4 6 32 36 48 92 48 165 0 79 -18 130 -53 147 -22
11 -22 11 -1 11 11 1 28 -7 36 -16z m138 -19 c19 -56 39 -147 30 -137 -12 14
-58 173 -50 173 5 0 14 -16 20 -36z m1320 -6 c0 -25 -4 -38 -9 -32 -8 8 -4 74
5 74 2 0 4 -19 4 -42z m-1673 17 c29 -20 60 -127 33 -110 -5 3 -10 15 -10 26
0 31 -29 79 -53 89 -13 5 -16 9 -7 9 9 1 25 -6 37 -14z m3294 -58 c-8 -8 -11
-1 -11 24 0 46 14 60 18 19 2 -18 -1 -37 -7 -43z m-2201 -181 c-12 -12 -14
146 -2 209 5 29 8 8 10 -79 2 -73 -1 -123 -8 -130z m1286 203 c-3 -18 -1 -38
5 -45 9 -11 9 -14 0 -14 -20 0 -32 48 -18 70 17 28 20 25 13 -11z m-2831 10
c170 -47 196 -210 50 -307 -56 -37 -126 -53 -221 -52 -123 2 -138 1 -183 -15
-24 -8 -46 -13 -48 -11 -3 2 5 22 16 44 l21 40 -21 46 c-16 38 -18 52 -10 84
19 70 115 150 206 173 46 11 146 10 190 -2z m1330 -61 c0 -40 4 -82 9 -93 8
-19 8 -19 -10 -2 -14 13 -18 32 -19 75 0 50 8 92 17 92 2 0 3 -33 3 -72z
m-304 -118 l-9 -85 0 114 c0 62 3 123 7 135 11 37 12 -73 2 -164z m133 113
c-8 -56 -22 -99 -23 -71 -1 23 20 118 25 118 3 0 2 -21 -2 -47z m-582 -43 c-3
-55 -1 -85 8 -95 8 -10 9 -15 2 -15 -6 0 -17 10 -23 22 -16 29 -3 168 15 168
1 0 1 -36 -2 -80z m1171 -12 c-3 -46 -7 -85 -9 -87 -2 -2 -4 35 -4 82 0 50 4
87 10 87 6 0 7 -32 3 -82z m-1373 46 c-15 -39 -12 -117 6 -142 16 -24 4 -31
-15 -8 -22 26 -14 176 9 176 6 0 5 -11 0 -26z m2377 -36 c-2 -13 -4 -5 -4 17
-1 22 1 32 4 23 2 -10 2 -28 0 -40z m-634 -10 c-8 -59 -13 -61 -13 -5 0 26 4
47 9 47 5 0 7 -19 4 -42z m-1343 -152 c-12 -12 -13 19 -2 119 l7 70 3 -89 c2
-53 -1 -93 -8 -100z m650 100 c-11 -36 -36 -63 -46 -53 -4 3 1 13 10 22 9 9
22 38 28 63 9 34 14 41 16 24 2 -12 -2 -38 -8 -56z m851 37 c13 -16 12 -17 -3
-4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m-1808 -120 c-10 -20 -24 -39 -31
-41 -7 -2 -2 16 13 44 14 26 26 56 26 68 2 15 3 12 6 -7 2 -15 -4 -44 -14 -64z
m658 20 c-10 -17 -10 -16 -11 3 0 23 13 41 18 25 2 -6 -1 -18 -7 -28z"/>
<path d="M3012 5385 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2980 5377 c0 -14 5 -29 10 -32 7 -4 8 3 4 22 -9 38 -14 42 -14 10z"/>
<path d="M3198 5367 c-3 -19 -2 -25 4 -19 6 6 8 18 6 28 -3 14 -5 12 -10 -9z"/>
<path d="M2955 5370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3265 5360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3103 5325 c8 -16 11 -17 15 -5 3 8 10 18 15 22 6 3 -1 5 -15 5 -23
-2 -25 -5 -15 -22z"/>
<path d="M3720 3588 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z"/>
<path d="M3927 3446 c-4 -10 -3 -15 2 -12 5 3 12 -2 15 -11 6 -15 8 -15 16 -3
6 8 10 20 10 25 0 6 -4 4 -9 -4 -7 -11 -10 -10 -18 4 -9 16 -11 16 -16 1z"/>
<path d="M2292 3061 c-11 -6 -10 -10 0 -19 11 -8 14 -7 15 9 2 22 2 21 -15 10z"/>
<path d="M2320 3000 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M2300 2965 c0 -3 9 -15 19 -26 16 -17 23 -19 38 -9 16 10 16 10 -4 5
-25 -7 -30 4 -10 25 10 12 9 12 -5 1 -12 -9 -18 -10 -18 -2 0 6 -4 11 -10 11
-5 0 -10 -2 -10 -5z"/>
<path d="M2379 2952 c7 -5 10 -15 7 -21 -4 -6 -2 -11 4 -11 17 0 8 33 -10 37
-12 4 -13 3 -1 -5z"/>
<path d="M2483 2924 c0 -17 2 -22 7 -13 5 6 6 18 3 26 -8 19 -10 16 -10 -13z"/>
<path d="M2390 2901 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2350 2896 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2430 2890 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2515 2840 c-4 -12 -2 -27 3 -32 8 -8 9 -4 5 13 -5 24 -5 24 11 4 17
-19 17 -19 5 5 -16 34 -16 34 -24 10z"/>
<path d="M2430 2840 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2390 2795 c-15 -18 1 -26 23 -10 15 10 15 14 3 18 -8 3 -20 0 -26
-8z"/>
<path d="M2570 2751 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2577 2703 c3 -7 6 -13 8 -13 2 0 5 6 8 13 3 8 -1 14 -8 14 -7 0 -11
-6 -8 -14z"/>
<path d="M2407 2687 c-11 -29 -9 -43 4 -30 6 6 9 19 7 29 -3 18 -4 18 -11 1z"/>
<path d="M2596 2683 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2610 2655 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M2671 2574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2528 2543 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M2700 2542 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M2731 2484 c6 -7 16 -11 21 -8 11 7 3 17 -18 20 -12 2 -13 -1 -3 -12z"/>
<path d="M2750 2449 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2678 2443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2804 2399 c1 -26 2 -27 14 -12 6 9 12 15 13 12 0 -2 2 -15 5 -28 3
-17 1 -22 -11 -18 -8 4 -17 2 -19 -2 -3 -5 5 -8 17 -7 30 1 31 39 2 64 l-22
19 1 -28z"/>
<path d="M2634 2409 c-3 -6 -1 -16 6 -22 10 -9 12 -8 7 5 -3 9 -1 19 6 22 9 3
9 5 0 5 -6 1 -15 -4 -19 -10z"/>
<path d="M2705 2380 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2712 2336 c7 -8 15 -12 17 -11 5 6 -10 25 -20 25 -5 0 -4 -6 3 -14z"/>
<path d="M2866 2338 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z"/>
<path d="M2648 2303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2885 2289 c-4 -6 -4 -13 -1 -16 3 -4 6 -1 6 6 0 7 5 9 12 5 7 -4 8
-3 4 4 -9 15 -13 15 -21 1z"/>
<path d="M2936 2253 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2966 2262 c-2 -4 4 -8 14 -8 10 0 16 4 14 8 -3 4 -9 8 -14 8 -5 0
-11 -4 -14 -8z"/>
<path d="M2780 2256 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3053 2050 c-11 -16 -10 -18 2 -14 8 4 15 12 15 20 0 19 -3 18 -17
-6z"/>
<path d="M3284 2025 c-4 -8 -13 -15 -22 -15 -8 0 -13 -2 -10 -5 10 -11 58 7
58 21 0 19 -19 18 -26 -1z"/>
<path d="M3290 1990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4367 1600 c-30 -35 -41 -101 -41 -230 0 -101 16 -143 56 -148 36 -4
54 25 67 108 20 122 11 246 -21 279 -25 28 -32 27 -61 -9z"/>
<path d="M4282 1555 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5273 1612 c-16 -10 -31 -342 -15 -342 62 0 84 51 86 201 1 75 -3
101 -17 122 -18 27 -33 33 -54 19z"/>
<path d="M3400 1750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3380 1680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3608 1716 c-3 -16 0 -27 9 -30 10 -4 12 1 7 22 -9 36 -11 37 -16 8z"/>
<path d="M3635 1715 c4 -16 11 -33 17 -36 5 -4 -4 -13 -21 -20 -18 -8 -35 -25
-41 -43 -12 -35 -7 -44 15 -22 9 9 12 16 6 16 -18 0 -12 14 16 34 l26 19 -6
-41 c-3 -23 -1 -42 4 -42 5 0 10 10 11 23 0 12 2 34 3 48 1 14 -7 41 -17 60
l-19 34 6 -30z"/>
<path d="M3590 1669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3666 1582 c-2 -4 -3 -22 -2 -40 1 -17 -1 -32 -6 -32 -4 0 -8 15 -9
33 0 32 0 32 -11 7 l-11 -25 -7 25 c-7 24 -7 23 -13 -10 -49 -270 -49 -260 -1
-260 l35 0 -3 90 c-2 69 0 90 10 90 9 0 11 7 7 20 -4 11 -2 20 4 20 6 0 11
-12 11 -27 0 -16 4 -34 9 -42 6 -10 9 1 9 33 -1 26 -5 49 -10 52 -5 3 -6 21
-2 40 6 32 1 44 -10 26z m-29 -94 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z"/>
<path d="M3575 1550 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3580 1511 c0 -7 -4 -10 -9 -7 -5 3 -12 -1 -15 -10 -9 -23 -7 -64 3
-64 9 0 33 81 25 89 -2 2 -4 -1 -4 -8z"/>
<path d="M3520 1431 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M3470 1117 c0 -16 37 -43 46 -34 2 2 0 15 -5 28 -5 13 -10 18 -10 10
-1 -11 -4 -11 -16 -1 -12 10 -15 10 -15 -3z"/>
<path d="M3725 1097 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M4021 1714 c4 -31 10 -58 13 -61 8 -8 -5 101 -14 110 -4 4 -3 -18 1
-49z"/>
<path d="M3850 1697 c13 -35 13 -60 1 -52 -11 6 -26 -33 -17 -42 3 -4 6 1 6
10 0 10 4 17 10 17 5 0 8 -23 6 -52 -3 -33 0 -56 7 -60 8 -5 9 -11 2 -15 -6
-5 -8 -28 -5 -53 l6 -45 8 58 c10 75 -2 221 -20 244 -13 16 -13 15 -4 -10z"/>
<path d="M4172 1689 c6 -16 12 -29 13 -27 6 6 -8 58 -16 58 -4 0 -3 -14 3 -31z"/>
<path d="M3822 1680 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3981 1679 c-1 -17 5 -29 15 -33 13 -5 14 -3 6 6 -5 7 -12 22 -15 33
-4 16 -5 14 -6 -6z"/>
<path d="M4167 1639 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M4020 1620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4022 1580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4131 1584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4151 1545 l1 -50 8 40 c5 22 4 44 -1 50 -5 5 -9 -11 -8 -40z"/>
<path d="M4030 1474 c0 -16 5 -36 10 -44 6 -10 7 -2 3 25 -7 49 -13 58 -13 19z"/>
<path d="M4060 1135 c0 -2 9 -9 20 -15 15 -8 20 -8 20 3 0 7 -9 13 -20 14 -11
1 -20 0 -20 -2z"/>
<path d="M4695 1611 c-3 -2 -5 -33 -5 -68 l0 -63 39 0 c22 0 43 5 47 12 11 18
7 97 -6 113 -11 14 -64 18 -75 6z"/>
<path d="M2980 1750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4918 873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4608 863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4984 686 c-7 -29 9 -56 33 -56 22 0 13 74 -9 78 -12 2 -19 -5 -24
-22z"/>
<path d="M4901 694 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4606 682 c-3 -3 -6 -20 -6 -39 0 -22 5 -33 14 -33 17 0 21 5 30 41
4 21 2 28 -14 32 -10 3 -21 2 -24 -1z"/>
<path d="M1196 498 c4 -7 3 -8 -4 -4 -15 9 -63 -4 -56 -15 3 -5 -2 -6 -10 -3
-21 8 -92 -43 -116 -83 -24 -41 -26 -90 -4 -122 10 -13 14 -33 11 -49 l-5 -27
118 -3 c146 -3 211 17 278 83 37 38 42 48 42 88 0 60 -33 99 -106 126 -60 23
-160 29 -148 9z m-6 -29 c13 -9 12 -10 -2 -5 -19 6 -25 -9 -8 -17 6 -3 5 -4
-2 -3 -14 3 -24 36 -11 36 4 0 15 -5 23 -11z m-22 -63 c8 -18 7 -27 -6 -39
-14 -15 -14 -17 4 -24 10 -4 25 -13 32 -20 16 -16 64 -17 79 -2 14 14 83 1 83
-17 0 -7 -11 -21 -25 -30 -40 -26 -145 -7 -199 36 -58 47 -69 75 -40 104 23
24 59 20 72 -8z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
